module services {
    export module tariff {
        export class countryVATExceptionService implements interfaces.tariff.ICountryVATExceptionService {
            static $inject = [
                "$resource",
                "ENV",
                "$http",
                "generalService",
                "$timeout"
            ];

            constructor(
                private $resource: ng.resource.IResourceService,
                private ENV: interfaces.applicationcore.serverConfig,
                private $http: ng.IHttpService,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService
            ) { }

            getCountryVATExceptionList(): ng.resource.IResourceClass<interfaces.tariff.ICountryVATException> {
                return this.$resource<interfaces.tariff.ICountryVATException>(
                    this.ENV.DSP_URL + "CountryVATException/GetCountryVATExceptionList",
                    {
                        connectSearch: "@connectSearch",
                        numberRecords: "@numberRecords",
                        pageNumber: "@pageNumber"
                    },
                    {
                        query: {
                            method: "GET",
                            isArray: true,
                            interceptor: {
                                response: config.appResourceDateInterceptor.parseResponseDates
                            },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    }
                );
            }

            getCountryVATExceptionListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + "CountryVATException/GetCountryVATExceptionListExcel?";

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                };
                searchObject = params.connectSearch;

                url += "connectSearch=" + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: "GET",
                    responseType: "arraybuffer",
                    url: url
                })
                    .then(response => {
                        var filename = response.headers()["x-filename"] || "download.xlsx";
                        var fileBlob = new Blob([response.data as BlobPart], {
                            type: "application/octet-stream"
                        });

                        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
                            // for IE browser
                            window.navigator.msSaveBlob(fileBlob, filename);
                        } else {
                            // for other browsers
                            var fileURL = window.URL.createObjectURL(fileBlob);
                            pdfDownload.href = fileURL;
                            pdfDownload.download = filename;
                            pdfDownload.click();
                            pdfDownload.remove();
                        }

                        this.$timeout(() => {
                            this.generalService.displayMessage(
                                "Your download has started.",
                                Enum.EnumMessageType.Success
                            );
                        });
                    })
                    .catch(errorResponse => {
                        var fileBlob = new Blob([errorResponse.data], {
                            type: "application/json"
                        });
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        const reader = new FileReader();

                        // This fires after the blob has been read/loaded.
                        reader.addEventListener("loadend", (e: ProgressEvent) => {
                            const text = e.target["result"];

                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(
                                    <interfaces.applicationcore.IMessageHandler>JSON.parse(text)
                                );
                            });
                        });

                        // Start reading the blob as text.
                        reader.readAsText(fileBlob);
                    });
            }

            saveCountryVATException(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CountryVATException/SaveCountryVATException", {
                });
            }

            removeCountryVATException(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CountryVATException/RemoveCountryVATException", {
                    id: '@Id',
                });
            }
        }
    }
    angular.module("app").service("countryVATExceptionService", services.tariff.countryVATExceptionService);
}